import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  mailTo: (str: ReactNode) => (
    <a href={`mailTo: ${str}`} style={{ color: '#D50000' }}>
      {str}
    </a>
  ),
};

export const faqTranslations = (intl: IntlShape) => ({
  faq: {
    title: intl.formatMessage({
      defaultMessage: 'Faq',
      description: 'Faq Page title',
    }),
    content: {
      how: {
        title: intl.formatMessage({
          defaultMessage: '1.	Come posso richiedere l’accredito ?',
          description: 'Faq content how title',
        }),
        res: {
          a: intl.formatMessage({
            defaultMessage:
              'Entra nella sezione “Calendario”, seleziona la partita di interesse e clicca sul bottone rosso “Richiedi Accredito”. Compila i campi Categoria e/o Testata solo se necessiti di modificare il tuo default. Conferma la richiesta di inserimento.  Ti arriverà subito una e-mail di conferma inserimento richiesta e successivamente, durante la settimana della partita, anche una e-mail che confermerà o negherà l’accredito richiesto. Nella e-mail di conferma accredito avrai a disposizione tutte le informazioni necessarie per presentarti allo stadio.',
            description: 'Faq content how res a',
          }),
        },
      },
      howPhoto: {
        title: intl.formatMessage({
          defaultMessage:
            '2.	Sono un fotografo, come posso richiedere l’accredito ?',
          description: 'Faq content how Photo title',
        }),
        res: {
          a: intl.formatMessage({
            defaultMessage:
              'Per la stagione 2022/23 gli accrediti relativi alla Lega Serie A e alla Coppa Italia vengono gestiti direttamente dalla Lega Serie A sul loro portale, quindi le modalità di richiesta restano invariate rispetto alla scorsa stagione. Ti arriverà una e-mail di conferma accredito per la singola partita anche dal nostro sistema.',
            description: 'Faq content how Photo res a',
          }),
          b: intl.formatMessage({
            defaultMessage:
              'Per la stagione 2022/23 gli accrediti relativi alla UEFA Champions League verranno gestiti attraverso questa piattaforma con le modalità sopra descritte.',
            description: 'Faq content how Photo res b',
          }),
        },
      },
      howSec: {
        title: intl.formatMessage({
          defaultMessage:
            '3.	Sono una segreteria: come posso richiedere gli accrediti per i miei redattori?',
          description: 'Faq content how Sec title',
        }),
        res: {
          a: intl.formatMessage({
            defaultMessage:
              'Entra nella sezione “Calendario”, seleziona “Gestisci Accrediti” a fianco alla partita di interesse. Seleziona i quadretti a fianco al nome o ai nomi dei redattori che vuoi accreditare e clicca su “Richiedi Accrediti”. Durante la settimana della partita ti arriverà una e-mail che confermerà o negherà l’accredito richiesto. Nella e-mail di conferma accredito avrai a disposizione tutte le informazioni necessarie per presentarti allo stadio.',
            description: 'Faq content how Sec res a',
          }),
        },
      },
      howSecEdit: {
        title: intl.formatMessage({
          defaultMessage:
            '4.	Sono una segreteria come posso modificare la lista dei giornalisti/fotografi che gestisco ?',
          description: 'Faq content how Sec edit title',
        }),
        res: {
          a: intl.formatMessage(
            {
              defaultMessage:
                'Scrivi a <mailTo>press@acmilan.com</mailTo> indicando Nome, Cognome, e-mail, data e luogo di nascita e numero di tessera del giornalista che si vuole cancellare dalla propria lista o di quello che si vuole aggiungere tra i redattori gestiti.',
              description: 'Faq content how Sec edit res a',
            },
            { ...htmlParserOptions }
          ),
        },
      },
      edit: {
        title: intl.formatMessage({
          defaultMessage:
            '5.	Come modifico o cancello una richiesta di accredito già inserita ?',
          description: 'Faq content how edit title',
        }),
        res: {
          a: intl.formatMessage({
            defaultMessage:
              'In caso si voglia modificare testata o categoria di una richiesta già inserita bisognerà procedere alla cancellazione della stessa e al suo reinserimento.',
            description: 'Faq content how edit res a',
          }),
          b: intl.formatMessage({
            defaultMessage:
              'Per cancellare una richiesta da approvare o già approvata, clicca sull’icona nera “Cestino” a fianco all’accredito di interesse.',
            description: 'Faq content how edit res b',
          }),
          c: intl.formatMessage({
            defaultMessage:
              'Per favore tieni presente che se per tre volte non ci si presenta ad un match senza aver disdetto l’accredito, le tue richieste di accredito per i match successivi potrebbero venire rifiutate.',
            description: 'Faq content how edit res c',
          }),
        },
      },
      time: {
        title: intl.formatMessage({
          defaultMessage:
            '6.	Quali sono le tempistiche per richiedere l’accredito ?',
          description: 'Faq content time title',
        }),
        res: {
          a: intl.formatMessage({
            defaultMessage:
              'Gli accrediti possono essere richiesti nel momento in cui si vede il bottone “Richiedi accredito” a fianco alla partita di interesse e fino a 6 giorni prima della data della partita (Es. Se la partita è programmata per sabato 10 settembre ore 20:45 sarà possibile fare richiesta fino alle 23:59 di domenica 4 settembre).',
            description: 'Faq content time res a',
          }),
        },
      },
      accepted: {
        title: intl.formatMessage({
          defaultMessage:
            '7.	Come faccio a sapere se la mia richiesta è stata accettata ?',
          description: 'Faq content accepted title',
        }),
        res: {
          a: intl.formatMessage({
            defaultMessage:
              'Durante la settimana della partita, ti arriverà una e-mail di conferma o rifiuto dell’accredito. In caso di conferma la e-mail conterrà tutte le informazioni necessarie per recarsi allo stadio.',
            description: 'Faq content accepted res a',
          }),
          b: {
            zero: intl.formatMessage({
              defaultMessage:
                'Per gli accrediti richiesti, visualizzerai inoltro lo stato a fianco ad ogni partita:',
              description: 'Faq content accepted res b zero',
            }),
            one: intl.formatMessage({
              defaultMessage:
                '“Richiesta inviata”: in questo caso la richiesta è stata correttamente inviata ed è in fase di verifica.',
              description: 'Faq content accepted res b one',
            }),
            two: intl.formatMessage({
              defaultMessage:
                '“Richiesta approvata”: se l’accredito è andato a buon fine e potrai recarti allo stadio.',
              description: 'Faq content accepted res b two',
            }),
            three: intl.formatMessage({
              defaultMessage:
                '“Richiesta rifiutata”: se l’accredito è stato negato.',
              description: 'Faq content accepted res b three',
            }),
          },
        },
      },
      editProfile: {
        title: intl.formatMessage({
          defaultMessage:
            '8.	Dove posso modificare o visualizzare le informazioni del mio profilo ?',
          description: 'Faq content editProfile title',
        }),
        res: {
          a: intl.formatMessage({
            defaultMessage:
              'Vai nella sezione Profilo, non tutte le informazioni sono modificabili: non sarà per esempio possibile modificare l’e-mail.',
            description: 'Faq content editProfile res a',
          }),
          b: intl.formatMessage({
            defaultMessage:
              'Per cambiare la propria password, entrare nel “Profilo”, selezionare il tab “Cambia Password” in alto nella schermata, e procedere ad inserire le password richieste.',
            description: 'Faq content editProfile res b',
          }),
        },
      },
      invalid: {
        title: intl.formatMessage({
          defaultMessage:
            '9.	Come posso richiedere l’accredito se ho capacità motorie ridotte o altri bisogni particolari ?',
          description: 'Faq content how invalid title',
        }),
        res: {
          a: intl.formatMessage(
            {
              defaultMessage:
                'Richiedi normalmente l’accredito sul portale e successivamente segnala le tue esigenze a <mailTo>press@acmilan.com.</mailTo> Ti verrà inviata una e-mail di conferma generica e quella con le modalità specifiche da seguire da <mailTo>press@acmilan.com</mailTo>.',
              description: 'Faq content how invalid res a',
            },
            { ...htmlParserOptions }
          ),
        },
      },
      pass: {
        title: intl.formatMessage({
          defaultMessage: '10.	Come richiedo il pass parcheggio? ',
          description: 'Faq content how pass title',
        }),
        res: {
          a: intl.formatMessage(
            {
              defaultMessage:
                'Una volta che ti è stata approvata la richiesta, richiedi il pass parcheggio inviando una e-mail a <mailTo>press@acmilan.com</mailTo>: AC Milan invierà i pass in base alle disponibilità.',
              description: 'Faq content how pass res a',
            },
            { ...htmlParserOptions }
          ),
        },
      },
    },
  },
});
