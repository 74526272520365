import { IntlShape } from 'react-intl';

export const usersManagementTranslations = (intl: IntlShape) => ({
  usersManagement: {
    configuration: {
      title: intl.formatMessage({
        defaultMessage: 'Gestione Utenti',
        description: 'Users Management page title',
      }),
      filterTitle: intl.formatMessage({
        defaultMessage: 'Filtri',
        description: 'Users Management page filter title',
      }),
      adminCreateTitle: intl.formatMessage({
        defaultMessage: 'Crea nuovo admin',
        description: 'Users Management create page admin title',
      }),
      journalistCreateTitle: intl.formatMessage({
        defaultMessage: 'Crea nuovo giornalista/fotografo',
        description: 'Users Management create page journalist title',
      }),
      secretariatCreateTitle: intl.formatMessage({
        defaultMessage: 'Crea nuova segreteria',
        description: 'Users Management create page secretariat title',
      }),
      adminEditTitle: intl.formatMessage({
        defaultMessage: 'Modifica Admin',
        description: 'Users Management edit page admin title',
      }),
      secretariatEditTitle: intl.formatMessage({
        defaultMessage: 'Modifica Segreteria',
        description: 'Users Management edit page secretariat title',
      }),
      secretariatListTitle: intl.formatMessage({
        defaultMessage: 'Liste Segreterie',
        description: 'Users Management list page secretariat title',
      }),
      journalistEditTitle: intl.formatMessage({
        defaultMessage: 'Modifica giornalista/fotografo',
        description: 'Users Management edit page journalist title',
      }),
      documentsTitle: intl.formatMessage({
        defaultMessage: 'Documenti Aggiuntivi',
        description: 'Users Management edit page documents title',
      }),
      pendingTitle: intl.formatMessage({
        defaultMessage: 'In attesa di impostazione password',
        description: 'Users Management edit page pending title',
      }),
      subtitles: {
        sendCredentials: intl.formatMessage({
          defaultMessage: 'Invia le credenziali di accesso al frontoffice',
          description: 'Users Management buttons send credentials',
        }),
        sendAgainCredentials: intl.formatMessage({
          defaultMessage: 'Invia di nuovo le credenziali',
          description: 'Users Management buttons send again credentials',
        }),
        alreadySentCredentials: intl.formatMessage({
          defaultMessage: 'Credenziali di accesso al frontoffice inviate.',
          description: 'Users Management buttons already sent credentials',
        }),
        alreadySentCredentialsAdmin: intl.formatMessage({
          defaultMessage: 'Credenziali di accesso inviate.',
          description:
            'Users Management buttons already sent credentials admin',
        }),
        admin: intl.formatMessage({
          defaultMessage: `Per le utenze admin la mail con le credenziali viene automaticamente inviata all'utente.`,
          description: 'Users Management create page sub admin',
        }),
        secretariatCreate: intl.formatMessage({
          defaultMessage: `Per le utenze "Segreteria" la mail con le credenziali viene automaticamente inviata all'utente.`,
          description: 'Users Management create page sub secretariat create',
        }),
        secretariatEditUsers: intl.formatMessage({
          defaultMessage: 'utenti gestiti',
          description:
            'Users Management create page sub secretariat edit users',
        }),
        secretariatEditList: intl.formatMessage({
          defaultMessage: 'Vedi/Modifica Lista',
          description: 'Users Management create page sub secretariat edit list',
        }),
      },
      listSecretariat: {
        title: intl.formatMessage({
          defaultMessage: 'Lista utenti gestiti da',
          description: 'Users Managementlist secretariat title',
        }),
        searchBar: intl.formatMessage({
          defaultMessage:
            'Cerca Nominativi per l’aggiunta in lista  (per Nome, Cognome o testata)...',
          description: 'Users Managementlist secretariat searchBar',
        }),
        noResults: intl.formatMessage({
          defaultMessage: '0 utenti gestiti dalla Segreteria',
          description: 'Users Managementlist secretariat no results',
        }),
        snackbars: {
          successCreate: intl.formatMessage({
            defaultMessage: 'Utente aggiunto correttamente',
            description: 'Users Managementlist snackbars success',
          }),
          successDelete: intl.formatMessage({
            defaultMessage: 'Utente correttamente rimosso',
            description: 'Users Managementlist snackbars success',
          }),
        },
      },
    },
    buttons: {
      addUser: intl.formatMessage({
        defaultMessage: 'Nuovo Utente',
        description: 'Users Management buttons add user',
      }),
      filter: intl.formatMessage({
        defaultMessage: 'Filtra',
        description: 'Users Management buttons filter',
      }),
      edit: intl.formatMessage({
        defaultMessage: 'Modifica',
        description: 'Users Management buttons edit',
      }),
      remove: intl.formatMessage({
        defaultMessage: 'Elimina',
        description: 'Users Management buttons remove',
      }),
      apply: intl.formatMessage({
        defaultMessage: 'Applica',
        description: 'Users Management buttons apply',
      }),
      clearAll: intl.formatMessage({
        defaultMessage: 'Cancella tutto',
        description: 'Users Management buttons clear all',
      }),
      create: intl.formatMessage({
        defaultMessage: 'Crea Utente',
        description: 'Users Management buttons create',
      }),
      cancel: intl.formatMessage({
        defaultMessage: 'Annulla',
        description: 'Users Management buttons cancel',
      }),
      saveEditing: intl.formatMessage({
        defaultMessage: 'Salva modifiche',
        description: 'Users Management buttons save editing',
      }),
    },
    tables: {
      users: {
        form: {
          categories: intl.formatMessage({
            defaultMessage: 'Categorie',
            description: 'Users Management tables user form categories',
          }),
          allCategories: intl.formatMessage({
            defaultMessage: 'Tutte',
            description: 'Users Management tables user form all categories',
          }),
          search: intl.formatMessage({
            defaultMessage: 'Cerca per Nome Cognome...',
            description: 'Users Management tables user form search',
          }),
        },
        tabs: {
          journalist: intl.formatMessage({
            defaultMessage: 'Giornalisti/Fotografi',
            description: 'Users Management tables user tabs journalists',
          }),
          secretariat: intl.formatMessage({
            defaultMessage: 'Segreterie',
            description: 'Users Management tables user tabs secretariats',
          }),
          admin: intl.formatMessage({
            defaultMessage: 'Admin',
            description: 'Users Management tables user tabs admin',
          }),
        },
        columns: {
          name: intl.formatMessage({
            defaultMessage: 'Nome',
            description: 'Users Management tables user columns name',
          }),
          surname: intl.formatMessage({
            defaultMessage: 'Cognome',
            description: 'Users Management tables user columns surname',
          }),
          email: intl.formatMessage({
            defaultMessage: 'Email',
            description: 'Users Management tables user columns email',
          }),
          organisation: intl.formatMessage({
            defaultMessage: 'Testata',
            description: 'Users Management tables user columns press',
          }),
          category: intl.formatMessage({
            defaultMessage: 'Categoria',
            description: 'Users Management tables user columns category',
          }),
          status: intl.formatMessage({
            defaultMessage: 'Stato',
            description: 'Users Management tables user columns status',
          }),
          createdAt: intl.formatMessage({
            defaultMessage: 'Creato il',
            description: 'Users Management tables user columns createdAt',
          }),
          accounts: intl.formatMessage({
            defaultMessage: 'Account Gestiti',
            description: 'Users Management tables user columns accounts',
          }),
          number: intl.formatMessage({
            defaultMessage: 'Numero tessera giornalistica',
            description: 'Users Management tables user columns number',
          }),
          date: intl.formatMessage({
            defaultMessage: 'Data di nascita',
            description: 'Users Management tables user columns date',
          }),
          genre: intl.formatMessage({
            defaultMessage: 'Genere',
            description: 'Users Management tables user columns genre',
          }),
          nation: intl.formatMessage({
            defaultMessage: 'Nazione di nascita',
            description: 'Users Management tables user columns nation',
          }),
          district: intl.formatMessage({
            defaultMessage: 'Provincia',
            description: 'Users Management tables user columns district',
          }),
          city: intl.formatMessage({
            defaultMessage: 'Città',
            description: 'Users Management tables user columns city',
          }),
          role: intl.formatMessage({
            defaultMessage: 'Tipologia Utente',
            description: 'Users Management tables user columns role',
          }),
        },
      },
    },
    categories: {
      italianPhoto: intl.formatMessage({
        defaultMessage: 'Italian Photographer',
        description: 'Users Management categories italianPhoto',
      }),
      foreignPhoto: intl.formatMessage({
        defaultMessage: 'Foreign Photographer',
        description: 'Users Management categories foreignPhoto',
      }),
      italianPress: intl.formatMessage({
        defaultMessage: 'Italian Press',
        description: 'Users Management categories italianPress',
      }),
      foreignPress: intl.formatMessage({
        defaultMessage: 'Foreign Press',
        description: 'Users Management categories foreignPress',
      }),
      observerStand: intl.formatMessage({
        defaultMessage: 'Observer Stand',
        description: 'Users Management categories observerStand',
      }),
      organisation: intl.formatMessage({
        defaultMessage: 'Ufficio Stampa Ospite',
        description: 'Users Management categories press',
      }),
      webMaster: intl.formatMessage({
        defaultMessage: 'Web Master',
        description: 'Users Management categories webMaster',
      }),
      radio: intl.formatMessage({
        defaultMessage: 'Radio',
        description: 'Users Management categories radio',
      }),
      tv: intl.formatMessage({
        defaultMessage: 'Tv',
        description: 'Users Management categories tv',
      }),
      techTv: intl.formatMessage({
        defaultMessage: 'Tecnici TV',
        description: 'Users Management categories tv',
      }),
    },
    messages: {
      success: {
        adminCreate: intl.formatMessage({
          defaultMessage: 'Utente Admin correttamente creato',
          description: 'Users Management messages success create admin',
        }),
        secretariatCreate: intl.formatMessage({
          defaultMessage: 'Utente Segreteria correttamente creato',
          description: 'Users Management messages success create secretariat',
        }),
        journalistCreate: intl.formatMessage({
          defaultMessage: 'Utente Giornalista correttamente creato',
          description: 'Users Management messages success create journalist',
        }),
        adminUpdate: intl.formatMessage({
          defaultMessage: 'Utente Admin correttamente modificato',
          description: 'Users Management messages success update admin',
        }),
        journalistUpdate: intl.formatMessage({
          defaultMessage: 'Utente Giornalista correttamente modificato',
          description: 'Users Management messages success update admin',
        }),
        secretariatUpdate: intl.formatMessage({
          defaultMessage: 'Utente Segreteria correttamente modificato',
          description: 'Users Management messages success update secretariat',
        }),
      },
      error: {
        journalistCredentials: intl.formatMessage({
          defaultMessage: `Errore nell'invio delle credenziali`,
          description: 'Users Management messages error journalist credentials',
        }),
      },
    },
  },
});
