import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useFetch from 'use-http';
import { RHFAutocompleteProps } from './Autocomplete.types';
import { SearchIcon } from 'assets';
import { useTranslations } from 'hooks';
import useDebounce from 'hooks/useDebounce';

const RHFAutocomplete = <T,>({
  name,
  rules,
  label,
  placeholder,
  charactersCount = 3,
  endpoint,
  disabled = false,
  freeSolo = false,
  inputList = [],
  renderCustomLabel,
  hideSearchIcon = false,
  customEndIcon = null,
  onCharacterSelect,
  onInputClear,
}: RHFAutocompleteProps<T>) => {
  const i18n = useTranslations();
  const { control, watch } = useFormContext();
  const { get, loading } = useFetch();
  const [options, setOptions] = useState<T[]>([]);
  const [characters, setCharacters] = useState('');
  const debouncedText = useDebounce(characters, 500);
  const ref = useRef<HTMLDivElement>();
  const fieldValue = watch(name);

  useEffect(() => {
    if (debouncedText && debouncedText.length >= charactersCount && endpoint) {
      get(`${endpoint}=${characters}`).then(res => {
        setOptions(res?.length ? res : []);
      });
    }
    if (
      debouncedText &&
      debouncedText !== '' &&
      debouncedText.length >= charactersCount &&
      inputList.length
    ) {
      setOptions(inputList);
    } else {
      setOptions([]);
    }
  }, [debouncedText]);

  useEffect(() => {
    {
      /*  Pure Art 🎨 */
    }
    if (!fieldValue && !!characters && ref) {
      const input = ref.current;
      if (input) {
        (
          input.querySelector(
            '.MuiAutocomplete-clearIndicator'
          ) as HTMLButtonElement
        )?.click();
      }
      setCharacters('');
    }
  }, [fieldValue]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          noOptionsText={i18n.general.form.autocomplete.noOptions}
          ref={ref}
          id={name}
          fullWidth
          loadingText={options.length === 0 ? 'Loading...' : ''}
          options={options}
          getOptionLabel={(option: string | T) =>
            typeof option === 'string'
              ? option
              : renderCustomLabel?.(option) || ''
          }
          onChange={(_event, value) => field.onChange(value)}
          onInputChange={(_event, value, reason) => {
            /*  Pure Art 2 🎨 */
            if (reason !== 'reset') {
              setCharacters(value);
              reason === 'clear' && onInputClear?.();
            } else {
              onCharacterSelect?.(value);
            }
          }}
          filterOptions={x => x}
          renderInput={params => {
            return (
              <TextField
                {...(freeSolo && {
                  onChange: field.onChange,
                })}
                {...params}
                fullWidth
                error={!!error}
                helperText={error?.message}
                autoComplete={'off'}
                label={label}
                placeholder={placeholder}
                inputRef={ref}
                disabled={disabled}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {!hideSearchIcon ? (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        customEndIcon
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            );
          }}
        />
      )}
    />
  );
};

export { RHFAutocomplete };
