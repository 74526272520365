import { Grid, Stack, Typography, useTheme } from '@mui/material';
import {
  PaddingColumn,
  FooterContainer,
  InnerContainer,
  SocialIconsColumn,
  NoPaddingColumn,
} from './PageFooter.styles';
import {
  FacebookIcon,
  TwitchIcon,
  InstagramIcon,
  LinkedinIcon,
  TikTokIcon,
  YoutubeIcon,
  TwitterIcon,
  AppleIcon,
} from 'assets';
import { useResponsive, useTranslations } from 'hooks';

const PageFooter = () => {
  const transalations = useTranslations();
  const theme = useTheme();
  const i18n = transalations.general.footer;
  const isDesktop = useResponsive('up', 'md');

  return (
    <FooterContainer>
      <InnerContainer>
        <Grid container>
          <Grid md={4} xs={12}>
            <PaddingColumn>
              <Typography
                variant={'subtitle1'}
                textTransform={'uppercase'}
                textAlign={isDesktop ? 'left' : 'center'}
                sx={{ mb: theme.spacing(2.5) }}
              >
                {i18n.sempreMilan}
              </Typography>
              <Typography
                variant={'caption'}
                textAlign={isDesktop ? 'left' : 'center'}
                sx={{ mb: '2px' }}
              >
                {i18n.copyright}
              </Typography>
              <Typography
                variant={'caption'}
                textAlign={isDesktop ? 'left' : 'center'}
                sx={{ mb: '2px' }}
              >
                {i18n.notDuplicate}
              </Typography>
              <Typography
                variant={'caption'}
                textAlign={isDesktop ? 'left' : 'center'}
                sx={{ mb: '2px' }}
              >
                {i18n.piva}
              </Typography>
              <Typography
                variant={'caption'}
                textAlign={isDesktop ? 'left' : 'center'}
                sx={{ mb: '2px' }}
              >
                {i18n.siae}
              </Typography>
            </PaddingColumn>
          </Grid>
          <Grid md={4} xs={12}>
            <PaddingColumn>
              <Typography
                variant={'subtitle2'}
                textTransform={'uppercase'}
                sx={{ mb: theme.spacing(2.5) }}
                textAlign={isDesktop ? 'left' : 'center'}
              >
                {i18n.socialMedia}
              </Typography>
              <Stack flexDirection={'row'} sx={{ m: isDesktop ? 0 : '0 auto' }}>
                <SocialIconsColumn>
                  <a
                    target={'_blank'}
                    href={'https://www.twitch.tv/acmilan'}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <TwitchIcon />
                  </a>
                  <a
                    target={'_blank'}
                    href={'https://www.tiktok.com/@acmilan?language=it'}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <TikTokIcon />
                  </a>
                </SocialIconsColumn>
                <SocialIconsColumn>
                  <a
                    target={'_blank'}
                    href={'https://www.facebook.com/ACMilan'}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    target={'_blank'}
                    href={'https://www.youtube.com/acmilan'}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <YoutubeIcon />
                  </a>
                </SocialIconsColumn>
                <SocialIconsColumn>
                  <a
                    target={'_blank'}
                    href={'https://www.instagram.com/acmilan/?hl=it'}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    target={'_blank'}
                    href={'https://twitter.com/acmilan'}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <TwitterIcon />
                  </a>
                </SocialIconsColumn>
                <SocialIconsColumn sx={{ marginRight: isDesktop ? 20 : 0 }}>
                  <a
                    target={'_blank'}
                    href={'https://it.linkedin.com/company/ac-milan'}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <LinkedinIcon />
                  </a>
                  <a
                    target={'_blank'}
                    href={'https://www.acmilan.com/it/fan/ac-milan-music'}
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <AppleIcon />
                  </a>
                </SocialIconsColumn>
              </Stack>
            </PaddingColumn>
          </Grid>
          <Grid md={4} xs={12}>
            <NoPaddingColumn>
              <Grid container>
                <Grid item md={2} xs={false} />
                <Grid item md={10} xs={12}>
                  <Stack
                    gap={'2px'}
                    alignItems={isDesktop ? 'flex-start' : 'center'}
                  >
                    <a
                      target={'_blank'}
                      href={'https://www.acmilan.com/it/club/sponsor'}
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <Typography
                        variant={'overline'}
                        textTransform={'uppercase'}
                        sx={{ textDecoration: 'underline' }}
                        textAlign={isDesktop ? 'left' : 'center'}
                      >
                        {i18n.partner}
                      </Typography>
                    </a>
                    <a
                      href="mailto:press@acmilan.com"
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <Typography
                        variant={'overline'}
                        textTransform={'uppercase'}
                        sx={{ textDecoration: 'underline' }}
                        textAlign={isDesktop ? 'left' : 'center'}
                      >
                        {i18n.contacts}
                      </Typography>
                    </a>
                    <a
                      target={'_blank'}
                      href={'/assets/GDPR_ACM.pdf'}
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <Typography
                        variant={'overline'}
                        textTransform={'uppercase'}
                        sx={{ textDecoration: 'underline' }}
                        textAlign={isDesktop ? 'left' : 'center'}
                      >
                        {i18n.privacy}
                      </Typography>
                    </a>
                    <a
                      target={'_blank'}
                      href={'https://www.acmilan.com/it/brand-protection'}
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <Typography
                        variant={'overline'}
                        textTransform={'uppercase'}
                        sx={{ textDecoration: 'underline' }}
                        textAlign={isDesktop ? 'left' : 'center'}
                      >
                        {i18n.brand}
                      </Typography>
                    </a>
                  </Stack>
                </Grid>
                {/* <Grid item md={6} xs={12}>
                  <Stack gap={'2px'}>
                    <Typography
                      variant={'overline'}
                      textTransform={'uppercase'}
                      sx={{ textDecoration: 'underline' }}
                      textAlign={isDesktop ? 'left' : 'center'}
                    >
                      {i18n.privacy}
                    </Typography>
                    <Typography
                      variant={'overline'}
                      textTransform={'uppercase'}
                      sx={{ textDecoration: 'underline' }}
                      textAlign={isDesktop ? 'left' : 'center'}
                    >
                      {i18n.note}
                    </Typography>
                    <Typography
                      variant={'overline'}
                      textTransform={'uppercase'}
                      sx={{ textDecoration: 'underline' }}
                      textAlign={isDesktop ? 'left' : 'center'}
                    >
                      {i18n.terms}
                    </Typography>
                    <Typography
                      variant={'overline'}
                      textTransform={'uppercase'}
                      sx={{ textDecoration: 'underline' }}
                      textAlign={isDesktop ? 'left' : 'center'}
                    >
                      {i18n.brand}
                    </Typography>
                    <Typography
                      variant={'overline'}
                      textTransform={'uppercase'}
                      sx={{ textDecoration: 'underline' }}
                      textAlign={isDesktop ? 'left' : 'center'}
                    >
                      {i18n.cookie}
                    </Typography>
                  </Stack>
                </Grid> */}
              </Grid>
            </NoPaddingColumn>
          </Grid>
        </Grid>
      </InnerContainer>
    </FooterContainer>
  );
};
export { PageFooter };
