import { ScrollToTop } from 'components';
import { ProgressBarStyle } from 'components/ProgressBar/ProgressBar.styles';
import MotionLazyContainer from 'components/animations/MotionLazyContainer/MotionLazyContainer';
import ThemeSettings from 'components/settings';
import { LoadingProvider, SnackbarProvider } from 'providers';
import Router from 'routes';
import ThemeProvider from 'theme';
import './theme/global.css';

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <SnackbarProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <LoadingProvider />
            <Router />
          </SnackbarProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
