import { styled } from '@mui/material';

export const FooterContainer = styled('footer')(({ theme }) => ({
  minHeight: 330,
  paddingTop: 37,
  paddingBottom: 47,
  backgroundColor: theme.palette.common.black,
  [theme.breakpoints.up('md')]: {
    paddingTop: 77,
  },
}));

export const InnerContainer = styled('div')({
  width: '90%',
  margin: '0 auto',
});

export const PaddingColumn = styled('div')(({ theme }) => ({
  width: '80%',
  margin: '20px auto 0',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    paddingLeft: 60,
    margin: 0,
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 119,
  },
}));

export const NoPaddingColumn = styled('div')(({ theme }) => ({
  margin: '20px auto 0',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    paddingLeft: 20,
    margin: 0,
  },
}));

export const SocialIconsColumn = styled('div')({
  display: 'flex',
  height: 57,
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginRight: 20,
});
