import { IntlShape } from 'react-intl';

export const profileTranslations = (intl: IntlShape) => ({
  profile: {
    title: intl.formatMessage({
      defaultMessage: 'Profilo utente',
      description: 'Profile Page title',
    }),
    breadcrumb: intl.formatMessage({
      defaultMessage: 'Profilo',
      description: 'Profile Page breadcrumb',
    }),
    tabs: {
      personalData: {
        title: intl.formatMessage({
          defaultMessage: 'Dati personali',
          description: 'Profile Page tabs personal data title',
        }),
      },
      changePass: {
        title: intl.formatMessage({
          defaultMessage: 'Cambia Password',
          description: 'Profile Page tabs change pass title',
        }),
        fields: {
          oldPass: intl.formatMessage({
            defaultMessage: 'Password attuale',
            description: 'Profile Page tabs change pass',
          }),
          newPass: intl.formatMessage({
            defaultMessage: 'Nuova password',
            description: 'Profile Page tabs change newPass',
          }),
          confirmPass: intl.formatMessage({
            defaultMessage: 'Conferma nuova password',
            description: 'Profile Page tabs change confirmPass',
          }),
        },
      },
    },
    fields: {
      name: intl.formatMessage({
        defaultMessage: 'Nome',
        description: 'Profile Page name field',
      }),
      surname: intl.formatMessage({
        defaultMessage: 'Cognome',
        description: 'Profile Page surname field',
      }),
      email: intl.formatMessage({
        defaultMessage: 'Email',
        description: 'Profile Page email address field',
      }),
      category: intl.formatMessage({
        defaultMessage: 'Categoria',
        description: 'Profile Page category field',
      }),
      organisation: intl.formatMessage({
        defaultMessage: 'Testata',
        description: 'Profile Page organisation field',
      }),
      organisationSub: intl.formatMessage({
        defaultMessage:
          'È necessario indicare una testata. Sarà comunque possibile modificarla in fase di richiesta accredito',
        description: 'Profile Page organisationSub field',
      }),
      marketing: intl.formatMessage({
        defaultMessage: 'Acconsenti a profilazione marketing',
        description: 'Profile Page marketing field',
      }),
    },
    snackbar: {
      passwordSuccess: intl.formatMessage({
        defaultMessage: 'Password modificata con successo',
        description: 'Profile Page snackbar passwordSuccess',
      }),
      updateSuccess: intl.formatMessage({
        defaultMessage: 'Utente correttamente modificato',
        description: 'Profile Page snackbar updateSuccess',
      }),
    },
  },
});
