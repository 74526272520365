import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  styled,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { IRHFCheckboxProps, IRHFMultiCheckboxProps } from './Checkbox.types';

('MuiFormControlLabel-label');

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
    color: theme.palette.grey[500],
  },
}));

export function RHFCheckbox({
  name,
  rules,
  disabled,
  formControlStyle,
  defaultValue,
  ...other
}: IRHFCheckboxProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl error={!!errors[name]} style={formControlStyle}>
      <StyledLabel
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Checkbox {...field} checked={field.value} disabled={disabled} />
            )}
          />
        }
        {...other}
      />
      {errors[name] && (
        <FormHelperText>{errors?.[name]?.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export function RHFMultiCheckbox({
  name,
  options,
  formGroupStyle,
  ...other
}: IRHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup style={formGroupStyle}>
            {options.map(option => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
