import { IntlShape } from 'react-intl';

export const generalTranslations = (intl: IntlShape) => ({
  general: {
    navbar: {
      home: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Navbar Home link',
      }),
      calendar: intl.formatMessage({
        defaultMessage: 'Calendario',
        description: 'Navbar Calendar link',
      }),
      usersManagement: intl.formatMessage({
        defaultMessage: 'Gestione Utenti',
        description: 'Navbar Users Management link',
      }),
      config: intl.formatMessage({
        defaultMessage: 'Configuratore Stagione',
        description: 'Navbar Config link',
      }),
      profile: intl.formatMessage({
        defaultMessage: 'Profilo',
        description: 'Navbar Profile link',
      }),
      contact: intl.formatMessage({
        defaultMessage: 'Contatti',
        description: 'Navbar contact link',
      }),
      archive: intl.formatMessage({
        defaultMessage: 'Archivio Stagioni',
        description: 'Navbar Archive link',
      }),
      logout: intl.formatMessage({
        defaultMessage: 'Logout',
        description: 'Navbar Logout link',
      }),
    },
    home: {
      title: intl.formatMessage({
        defaultMessage: 'Benvenuto, in A.C. Milan Accrediti Stampa',
        description: 'Homepage title',
      }),
    },
    time: {
      hours: intl.formatMessage({
        defaultMessage: 'Ore',
        description: 'General hours',
      }),
      minutes: intl.formatMessage({
        defaultMessage: 'Minuti',
        description: 'General minutes',
      }),
      seconds: intl.formatMessage({
        defaultMessage: 'Secondi',
        description: 'General seconds',
      }),
      today: intl.formatMessage({
        defaultMessage: 'Oggi',
        description: 'General today',
      }),
    },
    competitions: {
      CAMPIONATO: intl.formatMessage({
        defaultMessage: 'Campionato',
        description: 'General competition Campionato name',
      }),
      COPPA_ITALIA: intl.formatMessage({
        defaultMessage: 'Coppa Italia',
        description: 'General competition Coppa Italia name',
      }),
      CHAMPIONS_LEAGUE: intl.formatMessage({
        defaultMessage: 'Champions League',
        description: 'General competition Champions League name',
      }),
      EUROPA_LEAGUE: intl.formatMessage({
        defaultMessage: 'Europa League',
        description: 'General competition Europa League name',
      }),
      SUPERCOPPA_ITALIANA: intl.formatMessage({
        defaultMessage: 'Supercoppa Italiana',
        description: 'General competition Supercoppa Italiana name',
      }),
      FRIENDLY: intl.formatMessage({
        defaultMessage: 'Amichevole',
        description: 'General competition Amichevole name',
      }),
    },
    sampleSection: {
      sampleMessage: intl.formatMessage({
        defaultMessage: 'Ciao da react-intl! Di nuovo!',
        description: 'Sample message',
      }),
    },
    user: {
      status: {
        approved: intl.formatMessage({
          defaultMessage: 'Approvato',
          description: 'general user status approved',
        }),
        tobeApproved: intl.formatMessage({
          defaultMessage: 'Pending',
          description: 'general user status tobeApproved',
        }),
        tobeConfirmed: intl.formatMessage({
          defaultMessage: 'Da approvare',
          description: 'general user status tobeConfirmed',
        }),
        revoked: intl.formatMessage({
          defaultMessage: 'Revocato',
          description: 'general user status revoked',
        }),
        new: intl.formatMessage({
          defaultMessage: 'Utente interno',
          description: 'general user status new',
        }),
      },
      categories: {
        ITALIAN_PHOTOGRAPHER: intl.formatMessage({
          defaultMessage: 'Italian Photographer',
          description: 'general user category italian photographer',
        }),
        FOREIGN_PHOTOGRAPHER: intl.formatMessage({
          defaultMessage: 'Foreign Photographer',
          description: 'general user category foreign photographer',
        }),
        ITALIAN_PRESS: intl.formatMessage({
          defaultMessage: 'Italian Press',
          description: 'general user category italian press',
        }),
        FOREIGN_PRESS: intl.formatMessage({
          defaultMessage: 'Foreign Press',
          description: 'general user category foreign press',
        }),
        OBSERVER_STAND: intl.formatMessage({
          defaultMessage: 'Observer Stand',
          description: 'general user category observer stand',
        }),
        GUEST_PRESS_OFFICE: intl.formatMessage({
          defaultMessage: 'Ufficio Stampa Ospite',
          description: 'general user category guest press office',
        }),
        WEB_MASTER: intl.formatMessage({
          defaultMessage: 'Web Master',
          description: 'general user category web master',
        }),
        RADIO: intl.formatMessage({
          defaultMessage: 'Radio',
          description: 'general user category radio',
        }),
        TV: intl.formatMessage({
          defaultMessage: 'TV',
          description: 'general user category tv',
        }),
        TV_TECHNICIAN: intl.formatMessage({
          defaultMessage: 'TV Technician',
          description: 'general user category tv technician',
        }),
      },
      roles: {
        Admin: intl.formatMessage({
          defaultMessage: 'Admin',
          description: 'general user admin role',
        }),
        Journalist: intl.formatMessage({
          defaultMessage: 'Giornalista',
          description: 'general user journalist role',
        }),
        Photographer: intl.formatMessage({
          defaultMessage: 'Fotografo',
          description: 'general user photographer role',
        }),
        Secreteriat: intl.formatMessage({
          defaultMessage: 'Segreteria',
          description: 'general user secretariat role',
        }),
      },
    },
    errors: {
      fieldRequired: intl.formatMessage({
        defaultMessage: 'Campo richiesto',
        description: 'general errors field required',
      }),
      emailNotValid: intl.formatMessage({
        defaultMessage: 'Indirizzo email non valido',
        description: 'general errors Invalid Email',
      }),
      somethingWentWrong: intl.formatMessage({
        defaultMessage:
          'Qualcosa è andato storto, per favore riprova più tardi.',
        description: 'general errors Invalid Email',
      }),
      userAlreadyExists: intl.formatMessage({
        defaultMessage: 'Utente già registrato con questa email',
        description: 'general errors User Already Exists',
      }),
      oldPassWrong: intl.formatMessage({
        defaultMessage: 'Password attuale errata',
        description: 'general errors oldPassWrong',
      }),
      invalidDate: intl.formatMessage({
        defaultMessage: 'Data non valida',
        description: 'general errors Invalid Date',
      }),
      invalidTimeFormat: intl.formatMessage({
        defaultMessage: 'Formato ora non valido',
        description: 'general errors invalid time format',
      }),
      documentsOrPressRequired: intl.formatMessage({
        defaultMessage:
          'Numero di tessera oppure lettere di certificazione richieste',
        description: 'general errors documents Or Press Required',
      }),
      permissionDenied: intl.formatMessage({
        defaultMessage: 'Permesso negato',
        description: 'general errors permission denied',
      }),
    },
    form: {
      search: intl.formatMessage({
        defaultMessage: 'Cerca',
        description: 'general form search',
      }),
      autocomplete: {
        noOptions: intl.formatMessage({
          defaultMessage: 'Nessuna Opzione',
          description: 'general autocomplete nooption text',
        }),
      },
      privacy: intl.formatMessage({
        defaultMessage: `Dichiaro di aver letto l’informativa alla privacy e di accettare 
        i Terms&Condition`,
        description: 'general privacy text',
      }),
      upload: {
        dropFileOrClick: intl.formatMessage({
          defaultMessage: 'Trascina i file qui o fai click su',
          description: 'general form upload drop file or click',
        }),
        browse: intl.formatMessage({
          defaultMessage: 'sfoglia',
          description: 'general form upload browse',
        }),
        onYourMachine: intl.formatMessage({
          defaultMessage: 'nel tuo computer',
          description: 'general form upload on your machine',
        }),
        removeAll: intl.formatMessage({
          defaultMessage: 'Elimina tutti',
          description: 'general form upload remove all button',
        }),
      },
    },
    buttons: {
      ok: intl.formatMessage({
        defaultMessage: 'Ok',
        description: 'general ok button',
      }),
      cancel: intl.formatMessage({
        defaultMessage: 'Annulla',
        description: 'general cancel button',
      }),
      back: intl.formatMessage({
        defaultMessage: 'Indietro',
        description: 'general back button',
      }),
      remove: intl.formatMessage({
        defaultMessage: 'Elimina',
        description: 'general remove button',
      }),
      lightRemove: intl.formatMessage({
        defaultMessage: 'Rimuovi',
        description: 'general light remove button',
      }),
      clearAll: intl.formatMessage({
        defaultMessage: 'Cancella tutto',
        description: 'general clearAll button',
      }),
      elementsFound: intl.formatMessage({
        defaultMessage: 'Elementi trovati',
        description: 'general elementsFound button',
      }),
      download: intl.formatMessage({
        defaultMessage: 'Download',
        description: 'general download button',
      }),
      export: intl.formatMessage({
        defaultMessage: 'Esporta',
        description: 'general export button',
      }),
      confirm: intl.formatMessage({
        defaultMessage: 'Approva',
        description: 'general confirm button',
      }),
      reject: intl.formatMessage({
        defaultMessage: 'Rifiuta',
        description: 'general reject button',
      }),
    },
    dialogs: {
      deleteUser: {
        title: intl.formatMessage({
          defaultMessage: 'Sei sicuro di voler eliminare questo utente?',
          description: 'general dialog delete user',
        }),
        secretariatTitle: intl.formatMessage({
          defaultMessage:
            'Sei sicuro di voler rimuovere dalla lista questo utente?',
          description: 'general dialog delete user secretariatTitle',
        }),
      },
    },
    footer: {
      sempreMilan: intl.formatMessage({
        defaultMessage: '#sempremilan',
        description: 'general footer sempremilan',
      }),
      copyright: intl.formatMessage({
        defaultMessage:
          'Copyright © 2022 ACMilan.com. Tutti i diritti riservati.',
        description: 'general footer copyright',
      }),
      notDuplicate: intl.formatMessage({
        defaultMessage: 'Non duplicare o ridistribuire in nessuna forma.',
        description: 'general footer notDuplicate',
      }),
      piva: intl.formatMessage({
        defaultMessage: 'Partita IVA: 01073200154',
        description: 'general footer piva',
      }),
      siae: intl.formatMessage({
        defaultMessage: 'Licenza SIAE 5330/I/5051',
        description: 'general footer siae',
      }),
      socialMedia: intl.formatMessage({
        defaultMessage: 'ac milan sui social media',
        description: 'general footer socialMedia',
      }),
      mercato: intl.formatMessage({
        defaultMessage: 'calciomercato',
        description: 'general footer mercato',
      }),
      partner: intl.formatMessage({
        defaultMessage: 'partner',
        description: 'general footer partner',
      }),
      contacts: intl.formatMessage({
        defaultMessage: 'contatti',
        description: 'general footer contacts',
      }),
      privacy: intl.formatMessage({
        defaultMessage: 'privacy',
        description: 'general footer privacy',
      }),
      note: intl.formatMessage({
        defaultMessage: 'note legali',
        description: 'general footer note',
      }),
      terms: intl.formatMessage({
        defaultMessage: 'termini di utilizzo',
        description: 'general footer terms',
      }),
      brand: intl.formatMessage({
        defaultMessage: 'Brand Protection',
        description: 'general footer brand',
      }),
      cookie: intl.formatMessage({
        defaultMessage: 'Gestisci Cookie',
        description: 'general footer cookie',
      }),
    },
    maintenance: {
      title: intl.formatMessage({
        defaultMessage: 'Sito in Manutenzione',
        description: 'general maintenance page title',
      }),
    },
  },
});
