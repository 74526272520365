import { styled } from '@mui/material/styles';
import { IMainStyleProps } from './DashboardLayout.types';

import MilanBackground from 'assets/images/milanBackground.png';
import { HEADER, NAVBAR } from 'config';

export const Background = styled('div')(({ theme }) => ({
  background: theme.palette.grey[100],
  backgroundImage: `url(${MilanBackground})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  flex: '1 2 360px',
  zIndex: 1150,
}));

export const MainStyle = styled('main', {
  shouldForwardProp: prop =>
    !['collapseClick', 'layoutHasBackground', 'densePadding'].includes(
      prop as string
    ),
})<IMainStyleProps>(
  ({ collapseClick, layoutHasBackground, theme, densePadding }) => ({
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingInline: densePadding ? theme.spacing(3.225) : theme.spacing(5.625),
      paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      transition: theme.transitions.create('margin-left', {
        duration: theme.transitions.duration.shorter,
      }),
      ...(layoutHasBackground
        ? { flex: '2 0.25 780px' }
        : {
            width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
            flexGrow: 1,
          }),
      ...(collapseClick &&
        !layoutHasBackground && {
          marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
    },
  })
);
