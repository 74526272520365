import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  mailTo: (str: ReactNode) => (
    <a href={`mailTo: ${str}`} style={{ color: '#D50000' }}>
      {str}
    </a>
  ),
  b: (str: ReactNode) => <strong>{str}</strong>,
};

export const authTranslations = (intl: IntlShape) => ({
  auth: {
    login: {
      title: intl.formatMessage({
        defaultMessage: 'Benvenuto',
        description: 'Login Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Inserisci sotto le tue credenziali per accedere alla piattaforma di gestione degli accrediti di AC Milan',
        description: 'Login Page subtitle',
      }),
      buttons: {
        resetPassword: intl.formatMessage({
          defaultMessage: 'Hai dimenticato la password?',
          description: 'Login Page reset password button',
        }),
        signIn: intl.formatMessage({
          defaultMessage: 'Accedi',
          description: 'Login Page sign in button',
        }),
        register: intl.formatMessage({
          defaultMessage: 'Richiedi iscrizione',
          description: 'Login Page register button',
        }),
      },
      fields: {
        email: intl.formatMessage({
          defaultMessage: 'Email',
          description: 'Login Page email address field label',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Password',
          description: 'Login Page password field label',
        }),
        rememberMe: intl.formatMessage({
          defaultMessage: 'Ricordami',
          description: 'Login Page remember me checkbox label',
        }),
      },
      errors: {
        email: intl.formatMessage({
          defaultMessage: 'Inserisci un indirizzo email valido',
          description: 'Email field error message',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Password obbligatoria',
          description: 'Login page password field error message',
        }),
      },
      snackbars: {
        invalidCredentials: intl.formatMessage({
          defaultMessage: 'Credenziali non valide',
          description: 'Login page invalid credentials snackbar message',
        }),
      },
    },
    register: {
      title: intl.formatMessage({
        defaultMessage: 'Registrazione',
        description: 'Register Page title',
      }),
      titleConfirm: intl.formatMessage({
        defaultMessage:
          'Attendi la conferma via email da parte di AC Milan per poter accedere al sistema.',
        description: 'Register Page title confirm',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Inserisci tutte le informazioni necessarie per registrarti alla piattaforma di gestione degli accrediti di AC Milan',
        description: 'Register Page subtitle',
      }),
      subtitleConfirm: intl.formatMessage({
        defaultMessage: 'La tua richiesta è stata inviata correttamente.',
        description: 'Register Page subtitle confirm',
      }),
      registerService: intl.formatMessage(
        {
          defaultMessage:
            'Se sei un <b>Giornalista o un Fotografo</b> segui gli step di registrazione compilando il seguente form.',
          description: 'Register Page subtitle registerService',
        },
        { ...htmlParserOptions }
      ),
      registerServiceTwo: intl.formatMessage(
        {
          defaultMessage:
            'Se sei una <b>Segreteria</b> richiedi accesso scrivendo a <mailTo>press@acmilan.com</mailTo> e indicando: il tuo nome e cognome, la tua mail e, per ogni giornalista che vuoi ti sia abilitato: il suo nome, cognome, data e luogo di nascita, indirizzo email, numero di tessera del giornalista.',
          description: 'Register Page subtitle registerServiceTwo',
        },
        { ...htmlParserOptions }
      ),
      fields: {
        name: intl.formatMessage({
          defaultMessage: 'Nome',
          description: 'Register Page name address field label',
        }),
        email: intl.formatMessage({
          defaultMessage: 'Email',
          description: 'Register Page email address field label',
        }),
        upload: intl.formatMessage({
          defaultMessage:
            'Se non hai digitato il numero di tessera, carica eventuali lettere di certificazione.',
          description: 'Register Page upload field label',
        }),
      },
      buttons: {
        register: intl.formatMessage({
          defaultMessage: 'Richiedi iscrizione',
          description: 'Register Page register button',
        }),
        back: intl.formatMessage({
          defaultMessage: 'Indietro',
          description: 'Register Page back button',
        }),
        close: intl.formatMessage({
          defaultMessage: 'Chiudi',
          description: 'Register Page close button',
        }),
      },
    },
    resetPassword: {
      title: intl.formatMessage({
        defaultMessage: 'Recupera password',
        description: 'Reset password Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Inserisci il tuo indirizzo di posta elettronica, poi segui le istruzioni che riceverai via email per reimpostare la tua password',
        description: 'Reset password Page subtitle',
      }),
      fields: {
        email: intl.formatMessage({
          defaultMessage: 'Email',
          description: 'Reset password Page email address field label',
        }),
      },
      buttons: {
        send: intl.formatMessage({
          defaultMessage: 'Invia',
          description: 'Reset passwword Page send button',
        }),
        back: intl.formatMessage({
          defaultMessage: 'Indietro',
          description: 'Reset passwword Page back button',
        }),
      },
      errors: {
        emailNotValid: intl.formatMessage({
          defaultMessage: 'Inserisci un indirizzo Email valido',
          description: 'Reset passwword Page Invalid Email field error message',
        }),
      },
      snackbars: {
        success: {
          emailSent: intl.formatMessage({
            defaultMessage:
              'Email inviata, controlla la tua casella di posta elettronica',
            description: 'Reset password page email sent snackbar message',
          }),
        },
        errors: {},
      },
    },
    newPassword: {
      title: intl.formatMessage({
        defaultMessage: 'Imposta password',
        description: 'New Password Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Imposta la tua nuova password per accedere al sistema',
        description: 'New Password Page subtitle',
      }),
      requirements: {
        title: intl.formatMessage({
          defaultMessage: 'Requisiti Password',
          description: 'New Password Page password requirements',
        }),
        length: intl.formatMessage({
          defaultMessage: 'Contenere almeno 8 caratteri',
          description: 'New Password Page length requirement',
        }),
        spacialChars: intl.formatMessage({
          defaultMessage: 'Contenere caratteri speciali',
          description: 'New Password Page spacial characters requirements',
        }),
        numbers: intl.formatMessage({
          defaultMessage: 'Contenere numeri',
          description: 'New Password Page nubmers requirements',
        }),
        uppercase: intl.formatMessage({
          defaultMessage: 'Contenere lettere maiuscole',
          description: 'New Password Page uppercase requirements',
        }),
        lowercase: intl.formatMessage({
          defaultMessage: 'Contenere lettere minuscole',
          description: 'New Password Page lowercase requirements',
        }),
      },
      fields: {
        password: intl.formatMessage({
          defaultMessage: 'Nuova Password',
          description: 'New Password Page new password field label',
        }),
        confirmPassword: intl.formatMessage({
          defaultMessage: 'Conferma Password',
          description: 'New Password Page confirm new password field label',
        }),
        confirmEmail: intl.formatMessage({
          defaultMessage: 'Conferma Email',
          description: 'New Password Page confirm email field label',
        }),
      },
      buttons: {
        send: intl.formatMessage({
          defaultMessage: 'Invia',
          description: 'New Password Page send button',
        }),
      },
      errors: {
        passwordMismatch: intl.formatMessage({
          defaultMessage: 'Le password non corrispondono',
          description: 'New password - password mismatch error message',
        }),
        emailMismatch: intl.formatMessage({
          defaultMessage: 'Le email non corrispondono',
          description: 'Registration - email mismatch error message',
        }),
        passwordFormatNotValid: intl.formatMessage({
          defaultMessage:
            'La password deve rispettare i requisiti di sicurezza',
          description: 'Reset password Page Invalid Email format error message',
        }),
      },
    },
    passwordChanged: {
      title: intl.formatMessage({
        defaultMessage: 'Password impostata con successo!',
        description: 'Password changed page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Effettua la login per accedere alla piattaforma, utilizzando le tue credenziali',
        description: 'Password changed page subtitle',
      }),
      fields: {
        email: intl.formatMessage({
          defaultMessage: 'Email',
          description: 'Password changed page email address field label',
        }),
      },
      buttons: {
        login: intl.formatMessage({
          defaultMessage: 'Login',
          description: 'Password changed page Login button',
        }),
      },
    },
  },
});
