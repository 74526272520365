import { IntlShape } from 'react-intl';

export const seasonConfigurationTranslations = (intl: IntlShape) => ({
  seasonConfig: {
    pageTitles: {
      competitions: {
        serieA: intl.formatMessage({
          defaultMessage: 'Serie A',
          description: 'page title serie a',
        }),
        coppaItalia: intl.formatMessage({
          defaultMessage: 'Coppa Italia',
          description: 'page title coppa italia',
        }),
        championsLeague: intl.formatMessage({
          defaultMessage: 'Champions League',
          description: 'page title Champions League',
        }),
        europaLeague: intl.formatMessage({
          defaultMessage: 'Europa League',
          description: 'page title europa League',
        }),
        superCoppaItaliana: intl.formatMessage({
          defaultMessage: 'Supercoppa Italiana',
          description: 'page title super coppa italia',
        }),
        amichevoli: intl.formatMessage({
          defaultMessage: 'Amichevoli ',
          description: 'page title amichevoli',
        }),
      },
      calendar: intl.formatMessage({
        defaultMessage: 'Calendario',
        description: 'page title calendario',
      }),
      editSeason: intl.formatMessage({
        defaultMessage: 'Modifica Stagione',
        description: 'page title edit season',
      }),
      newSeason: intl.formatMessage({
        defaultMessage: 'Nuova Stagione',
        description: 'page title new season',
      }),
    },
    tables: {
      competitions: {
        columns: {
          name: intl.formatMessage({
            defaultMessage: 'Partita',
            description: 'table competition column match',
          }),
          date: intl.formatMessage({
            defaultMessage: 'Data',
            description: 'table competition column date',
          }),
          hour: intl.formatMessage({
            defaultMessage: 'Orario',
            description: 'table competition column hour',
          }),
          status: intl.formatMessage({
            defaultMessage: 'Stato     ',
            description: 'table competition column status',
          }),
        },
      },
    },
    buttons: {
      newMatch: intl.formatMessage({
        defaultMessage: 'Nuova Partita',
        description: 'buttons new match',
      }),
      newSeason: intl.formatMessage({
        defaultMessage: 'Nuova Stagione',
        description: 'buttons new season',
      }),
      edit: intl.formatMessage({
        defaultMessage: 'Modifica',
        description: 'buttons edit',
      }),
      remove: intl.formatMessage({
        defaultMessage: 'Elimina',
        description: 'buttons remove',
      }),
      confirmed: intl.formatMessage({
        defaultMessage: 'Confermata',
        description: 'buttons confirmed',
      }),
      tobeConfirmed: intl.formatMessage({
        defaultMessage: 'Data/Ora Da Confermare',
        description: 'buttons to be confirmed',
      }),
    },
    fields: {
      team1Label: intl.formatMessage({
        defaultMessage: 'Squadra 1',
        description: 'field team 1 new match',
      }),
      team2Label: intl.formatMessage({
        defaultMessage: 'Squadra 2',
        description: 'field team 2 new match',
      }),
      dateLabel: intl.formatMessage({
        defaultMessage: 'Data',
        description: 'field data new match',
      }),
      timeLabel: intl.formatMessage({
        defaultMessage: 'Orario',
        description: 'field time new match',
      }),
      stageLabel: intl.formatMessage({
        defaultMessage: 'Giornata/Fase',
        description: 'field stage new match',
      }),
      stateLabel: intl.formatMessage({
        defaultMessage: 'Stato',
        description: 'field state new match',
      }),
    },
  },
  seasonDetail: {
    pageTitles: {
      configuredMatches: intl.formatMessage({
        defaultMessage: 'Partite configurate',
        description: 'season configured matches',
      }),
      saveSeasonAlertMessage: intl.formatMessage({
        defaultMessage:
          'Inserire un nome per la Nuova Stagione e salvare. Sarà poi possibile configurare le partite della Stagione',
        description: 'season save alert',
      }),
    },
    buttons: {
      publishSeason: intl.formatMessage({
        defaultMessage: 'Pubblica',
        description: 'publish new season',
      }),
      archiveSeason: intl.formatMessage({
        defaultMessage: 'Archivia',
        description: 'archive new season',
      }),
      saveSeason: intl.formatMessage({
        defaultMessage: 'Salva',
        description: 'save new season',
      }),
      deleteSeason: intl.formatMessage({
        defaultMessage: 'Elimina',
        description: 'delete new season',
      }),
      newSeasonBreadcrumbLabel: intl.formatMessage({
        defaultMessage: 'Nuova Stagione',
        description: 'breadcrumb new season',
      }),
      editSeasonBreadcrumbLabel: intl.formatMessage({
        defaultMessage: 'Modifica Stagione',
        description: 'breadcrumb edit season',
      }),
    },
    modal: {
      deleteSeasonConfirmMessage: intl.formatMessage({
        defaultMessage: 'Sei sicuro di voler eliminare la stagione?',
        description: 'delete season confirm message',
      }),
      deleteSeasonConfirmTitle: intl.formatMessage({
        defaultMessage: 'Attenzione',
        description: 'delete season confirm title',
      }),
      publishSeasonConfirmMessage: intl.formatMessage({
        defaultMessage: 'Sei sicuro di voler pubblicare la stagione?',
        description: 'publish season confirm message',
      }),
      publishSeasonConfirmTitle: intl.formatMessage({
        defaultMessage: 'Attenzione',
        description: 'publish season confirm title',
      }),
      publishSeasonConfirmButton: intl.formatMessage({
        defaultMessage: 'Conferma',
        description: 'publish season confirm button',
      }),
      exportDelete: {
        title: intl.formatMessage({
          defaultMessage: 'Sei sicuro di voler eliminare la stagione',
          description: 'match modal export delete title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage: 'Risultano già accreditati per questa stagione:',
          description: 'match modal export delete subtitle',
        }),
        buttons: {
          exportContacts: intl.formatMessage({
            defaultMessage: 'Esporta contatti',
            description: 'match modal export delete export contacts button',
          }),
        },
      },
    },
    snackbar: {
      error: {
        seasonFetchError: intl.formatMessage({
          defaultMessage: 'Errore nel recupero della stagione',
          description: 'fetch season error',
        }),
        seasonsFetchError: intl.formatMessage({
          defaultMessage: 'Errore nel recupero della lista delle stagioni',
          description: 'fetch seasons error',
        }),
        seasonsDeleteError: intl.formatMessage({
          defaultMessage: "Errore durante l'eliminazione della stagione",
          description: 'delete season error',
        }),
        seasonsCreateError: intl.formatMessage({
          defaultMessage: 'Errore durante la creazione della stagione',
          description: 'create season error',
        }),
        seasonsUpdateError: intl.formatMessage({
          defaultMessage: 'Errore durante la modifica della stagione',
          description: 'edit season error',
        }),
      },
      success: {
        seasonsDeleteSuccess: intl.formatMessage({
          defaultMessage: 'Stagione eliminata correttamente',
          description: 'delete season success',
        }),
        seasonsCreateSuccess: intl.formatMessage({
          defaultMessage: 'Stagione creata con successo',
          description: 'create season success',
        }),
        seasonsUpdateSuccess: intl.formatMessage({
          defaultMessage: 'Stagione modificata con successo',
          description: 'edit season success',
        }),
      },
    },
    statusList: {
      Active: intl.formatMessage({
        defaultMessage: 'Attiva',
        description: 'season status list active',
      }),
      Draft: intl.formatMessage({
        defaultMessage: 'Bozza',
        description: 'season status list draft',
      }),
      Archived: intl.formatMessage({
        defaultMessage: 'Archiviata',
        description: 'season status list archived',
      }),
    },
    fields: {
      nameLabel: intl.formatMessage({
        defaultMessage: 'Nome',
        description: 'new season name',
      }),
    },
  },
  matchList: {
    mapping: {
      matchStatus: {
        confirmed: intl.formatMessage({
          defaultMessage: 'Confermato',
          description: 'match status confirmed',
        }),
        toconfirm: intl.formatMessage({
          defaultMessage: 'Data e ora da confermare',
          description: 'match status to confirm',
        }),
      },
    },
    snackbar: {
      error: {
        createMatchError: intl.formatMessage({
          defaultMessage: 'Errore durante la creazione del match',
          description: 'snackbar error create match',
        }),
        deleteMatchError: intl.formatMessage({
          defaultMessage: 'Errore durante la cancellazione del match',
          description: 'snackbar error delete match',
        }),
        editMatchError: intl.formatMessage({
          defaultMessage: 'Errore durante la modifica del match',
          description: 'snackbar error edit match',
        }),
        listMatchError: intl.formatMessage({
          defaultMessage: 'Errore nel recupero dei match',
          description: 'snackbar error list match',
        }),
        getMatchError: intl.formatMessage({
          defaultMessage: 'Errore nel recupero del match',
          description: 'snackbar error get match',
        }),
      },
      success: {
        createMatchSuccess: intl.formatMessage({
          defaultMessage: 'Creazione del match avvenuta con successo',
          description: 'snackbar error create match',
        }),
        deleteMatchSuccess: intl.formatMessage({
          defaultMessage: 'Cancellazione del match avvenuta con successo',
          description: 'snackbar error delete match',
        }),
        editMatchSuccess: intl.formatMessage({
          defaultMessage: 'Modifica del match avvenuta con successo',
          description: 'snackbar error edit match',
        }),
      },
    },
    modal: {
      delete: {
        title: intl.formatMessage({
          defaultMessage: 'Attenzione',
          description: 'match modal delete title',
        }),
        message: intl.formatMessage({
          defaultMessage: 'Sei sicuro di voler cancellare la partita?',
          description: 'match modal delete message',
        }),
        confirmButton: intl.formatMessage({
          defaultMessage: 'Elimina',
          description: 'match modal delete button',
        }),
      },
      exportDelete: {
        title: intl.formatMessage({
          defaultMessage: 'Sei sicuro di voler eliminare la partita Milan',
          description: 'match modal export delete title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage: 'Risultano già accreditati per questa partita:',
          description: 'match modal export delete subtitle',
        }),
        buttons: {
          exportContacts: intl.formatMessage({
            defaultMessage: 'Esporta contatti',
            description: 'match modal export delete export contacts button',
          }),
        },
      },
    },
    breadcrumb: {
      season: intl.formatMessage({
        defaultMessage: 'Stagione',
        description: 'match list breadcrumb season',
      }),
    },
  },
});
