const TwitchIcon = ({ fillColor = 'none' }: { fillColor?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00014 2L3.42871 5.57143V18.4286H7.71443V22L11.2859 18.4286H14.143L20.5716 12V2H7.00014ZM19.143 11.2857L16.2859 14.1429H13.4287L10.9287 16.6429V14.1429H7.71443V3.42857H19.143V11.2857Z"
        fill="white"
      />
      <path
        d="M17.0001 5.92857H15.5716V10.2143H17.0001V5.92857Z"
        fill="white"
      />
      <path d="M13.0716 5.92857H11.643V10.2143H13.0716V5.92857Z" fill="white" />
    </svg>
  );
};

export default TwitchIcon;
