import { useCallback } from 'react';
import create from 'zustand';
import { defaultLang } from 'config';
import { IGlobalStore } from 'types/stores';

const useStore = create<IGlobalStore>(
  (set): IGlobalStore => ({
    activeLocale: defaultLang.value,
    loading: false,
    requestedLocation: null,
    dynamicBreadcrumbs: { seasonId: '', competitionSlug: '', matchId: '' },
    showLoader: () => set(() => ({ loading: true })),
    hideLoader: () => set(() => ({ loading: false })),
  })
);

const useGlobalSelector = () => useStore(useCallback(state => state, []));

export { useGlobalSelector, useStore as useGlobalStore };
