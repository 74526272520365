import { ReactNode, useMemo } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { defaultLang } from 'config';
import enMessages from 'i18n/compiled-lang/en.json';
import itMessages from 'i18n/compiled-lang/it.json';
import { useGlobalSelector } from 'stores';

import { Locales } from 'types/intl';

const IntlProvider = ({ children }: { children: ReactNode }) => {
  const { activeLocale } = useGlobalSelector();

  const messages = useMemo(() => {
    switch (activeLocale) {
      case Locales.EN:
        return enMessages;
      case Locales.IT:
        return itMessages;
      default:
        return itMessages;
    }
  }, [activeLocale]);

  return (
    <ReactIntlProvider
      locale={activeLocale}
      defaultLocale={defaultLang.value}
      messages={messages}
    >
      {children}
    </ReactIntlProvider>
  );
};

export { IntlProvider };
