import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  mailTo: (str: ReactNode) => (
    <a href={`mailto:${str}`} style={{ color: '#D50000' }}>
      {str}
    </a>
  ),
};

export const calendarTranslations = (intl: IntlShape) => ({
  calendar: {
    navigation: {
      breadcrumbs: {
        matchId: intl.formatMessage({
          defaultMessage: 'Dettaglio Gara',
          description: 'Calendar management breadcrumb',
        }),
        creditManagement: intl.formatMessage({
          defaultMessage: 'Gestione Accrediti',
          description: 'Calendar management breadcrumb credit management',
        }),
        newCredit: intl.formatMessage({
          defaultMessage: 'Nuovo Accredito',
          description: 'Calendar management breadcrumb new credit',
        }),
        creditDetail: intl.formatMessage({
          defaultMessage: 'Dettaglio Accredito',
          description: 'Calendar management breadcrumb credit detail',
        }),
        importRecap: intl.formatMessage({
          defaultMessage: 'Riepilogo import',
          description: 'Calendar management breadcrumb import recap',
        }),
      },
    },
    home: {
      tabs: {
        pastEvents: intl.formatMessage({
          defaultMessage: 'Eventi passati',
          description: 'Calendar tabs past events',
        }),
        currentEvents: intl.formatMessage({
          defaultMessage: 'Eventi correnti',
          description: 'Calendar tabs current events',
        }),
      },
      picker: {
        selectADate: intl.formatMessage({
          defaultMessage: 'Seleziona una data',
          description: 'Calendar date picker title',
        }),
      },
      filters: {
        competitions: {
          name: intl.formatMessage({
            defaultMessage: 'Competizioni',
            description: 'Calendar filters competitions',
          }),
          options: {
            all: intl.formatMessage({
              defaultMessage: 'Tutte',
              description: 'Calendar filters competitions all',
            }),
          },
        },
        order: {
          name: intl.formatMessage({
            defaultMessage: 'Ordinamento',
            description: 'Calendar filters order',
          }),
          options: {
            asc: intl.formatMessage({
              defaultMessage: 'Crescente per data',
              description: 'Calendar filters order ASC',
            }),
            desc: intl.formatMessage({
              defaultMessage: 'Descrescente per data',
              description: 'Calendar filters order DESC',
            }),
          },
        },
      },
      cards: {
        accreditations: {
          status: {
            Pending: intl.formatMessage({
              defaultMessage: 'Richiesta inviata',
              description:
                'Calendar management pending request accreditation label',
            }),
            Rejected: intl.formatMessage({
              defaultMessage: 'Richiesta rifiutata',
              description:
                'Calendar management rejected request accreditation label',
            }),
            Approved: intl.formatMessage({
              defaultMessage: 'Richiesta approvata',
              description:
                'Calendar management approved request accreditation label',
            }),
          },
          request: intl.formatMessage({
            defaultMessage: 'Richiedi accredito',
            description: 'Calendar management request accreditation button',
          }),
          requestsClosed: intl.formatMessage({
            defaultMessage: 'Le richieste per la partita sono chiuse',
            description: 'Calendar management requests closed',
          }),
          creditManagement: intl.formatMessage({
            defaultMessage: 'Gestisci accrediti',
            description: 'Calendar management accreditation managament button',
          }),
          aggregate: {
            total: intl.formatMessage({
              defaultMessage: 'Richieste',
              description: 'Calendar management request aggregate total',
            }),
            approved: intl.formatMessage({
              defaultMessage: 'Approvate',
              description: 'Calendar management request aggregate approved',
            }),
            rejected: intl.formatMessage({
              defaultMessage: 'Rifiutate',
              description: 'Calendar management request aggregate rejected',
            }),
          },
          alert: {
            expiring: intl.formatMessage({
              defaultMessage: 'Le richieste di accredito verranno chiuse tra',
              description: 'Calendar home match card expiring alert',
            }),
            longTerm: intl.formatMessage({
              defaultMessage: 'Le richieste di accredito verranno chiuse il',
              description: 'Calendar home match card long term alert',
            }),
            hoursAnd: intl.formatMessage({
              defaultMessage: 'ore e',
              description: 'Calendar home match card long term hours and',
            }),
            minutes: intl.formatMessage({
              defaultMessage: 'minuti',
              description: 'Calendar home match card long term minutes',
            }),
            seconds: intl.formatMessage({
              defaultMessage: 'secondi',
              description: 'Calendar home match card long term secondi',
            }),
          },
        },
      },
      dialog: {
        accreditationRequest: {
          fields: {
            category: intl.formatMessage({
              defaultMessage: 'Categoria',
              description:
                'Calendar accreditation request dialog category field',
            }),
            organisation: intl.formatMessage({
              defaultMessage: 'Testata',
              description:
                'Calendar accreditation request dialog organisation field',
            }),
          },
          organisationText: intl.formatMessage({
            defaultMessage:
              'Si prega di inserire la sola testata per la quale si richiede questo accredito',
            description:
              'Calendar accreditation request dialog organisation field description',
          }),
          requestButton: intl.formatMessage({
            defaultMessage: 'Richiedi Accredito',
            description: 'Calendar accreditation request dialog request button',
          }),
          outroText: intl.formatMessage({
            defaultMessage: 'La richiesta dovrà essere approvata da AC Milan',
            description: 'Calendar accreditation request dialog outro text',
          }),
        },
        accreditationDeletion: {
          title: intl.formatMessage({
            defaultMessage: 'Elimina richiesta di accredito',
            description: 'Calendar accreditation delition dialog title',
          }),
          content: intl.formatMessage({
            defaultMessage:
              'Confermi di voler annullare la tua richiesta di accredito? Verrà inoltrata una comunicazione ad AC Milan',
            description: 'Calendar accreditation delition dialog subtitle',
          }),
          contentOf: intl.formatMessage({
            defaultMessage:
              'Confermi di voler annullare la richiesta di accredito di',
            description: 'Calendar accreditation delition dialog contentOf',
          }),
          contentOfCommunication: intl.formatMessage({
            defaultMessage: 'Verrà inoltrata una comunicazione ad AC Milan',
            description:
              'Calendar accreditation delition dialog contentOfCommunication',
          }),
          confirmButton: intl.formatMessage({
            defaultMessage: 'Conferma',
            description: 'Calendar accreditation request dialog confirm button',
          }),
        },
      },
      snackbar: {
        accreditationCreated: intl.formatMessage({
          defaultMessage:
            "La tua richiesta è stata presa in carico. Ti avviseremo dell'esito tramite Email.",
          description: 'Calendar home accreditation created snackbar',
        }),
        accreditationDeleted: intl.formatMessage({
          defaultMessage: 'Richiesta di accredito eliminato con successo',
          description: 'Calendar home accreditation deleted snackbar',
        }),
      },
    },
    match: {
      recap: {
        date: intl.formatMessage({
          defaultMessage: 'Data',
          description: 'Calendar Match recap date',
        }),
        time: intl.formatMessage({
          defaultMessage: 'Ora',
          description: 'Calendar Match recap time',
        }),
        competitionType: intl.formatMessage({
          defaultMessage: 'Tipologia',
          description: 'Calendar Match recap competition type',
        }),
        dayNumber: intl.formatMessage({
          defaultMessage: 'Giornata',
          description: 'Calendar Match recap day number',
        }),
        phase: intl.formatMessage({
          defaultMessage: 'Fase',
          description: 'Calendar Match recap phase',
        }),
      },
      requestCards: {
        journalistsTitle: intl.formatMessage({
          defaultMessage: 'Giornalisti',
          description: 'Calendar Match requests card journalists title',
        }),
        photographersTitle: intl.formatMessage({
          defaultMessage: 'Fotografi',
          description: 'Calendar Match requests card photographers title',
        }),
        requestsToHandle: intl.formatMessage({
          defaultMessage: 'Richieste da gestire',
          description: 'Calendar Match requests card requests to handle',
        }),
        totalRequests: intl.formatMessage({
          defaultMessage: 'Richieste ricevute',
          description: 'Calendar Match requests card total requests',
        }),
        confirmedRequests: intl.formatMessage({
          defaultMessage: 'Richieste confermate',
          description: 'Calendar Match requests card confirmed requests',
        }),
        rejectedRequests: intl.formatMessage({
          defaultMessage: 'Richieste rifiutate',
          description: 'Calendar Match requests card rejected requests',
        }),
        handleButton: intl.formatMessage({
          defaultMessage: 'Gestisci',
          description: 'Calendar Match requests card handle button',
        }),
      },
      buttons: {
        handleAccreditations: intl.formatMessage({
          defaultMessage: 'Gestisci Accrediti',
          description: 'Calendar Match Button Handle Accreditations',
        }),
        showGrandstand: intl.formatMessage({
          defaultMessage: 'Visualizza tribuna',
          description: 'Calendar Match Button Show Grandstand',
        }),
        printLists: {
          filename: intl.formatMessage({
            defaultMessage: 'Elenchi',
            description: 'Calendar Match Button Print Lists Filename',
          }),
          label: intl.formatMessage({
            defaultMessage: 'Stampa elenchi',
            description: 'Calendar Match Button Print Lists Label',
          }),
        },
        printSingleList: {
          filename: intl.formatMessage({
            defaultMessage: 'Singole elenco',
            description: 'Calendar Match Button Print Single List Filename',
          }),
          label: intl.formatMessage({
            defaultMessage: 'Stampa singole elenco',
            description: 'Calendar Match Button Print Single List Label',
          }),
        },
        printPlaceholders: {
          filename: intl.formatMessage({
            defaultMessage: 'Segnaposti',
            description: 'Calendar Match Button Print Placeholders Filename',
          }),
          label: intl.formatMessage({
            defaultMessage: 'Stampa segnaposti',
            description: 'Calendar Match Button Print Placeholders Label',
          }),
        },
        excelExport: intl.formatMessage({
          defaultMessage: 'Esporta informazioni in Excel',
          description: 'Calendar Match Button Export Excel',
        }),
      },
      export: {
        placeHoldersPdf: {
          seat: intl.formatMessage({
            defaultMessage: 'Posto N.',
            description: 'Calendar Match export placeHoldersPdf seat label',
          }),
        },
        singleListPdf: {
          modal: {
            title: intl.formatMessage({
              defaultMessage: 'Selezionare una categoria',
              description: 'Calendar Match export singleListPdf modal title',
            }),
            category: intl.formatMessage({
              defaultMessage: 'Categoria',
              description: 'Calendar Match export singleListPdf modal category',
            }),
          },
          title: intl.formatMessage({
            defaultMessage: 'Richieste di Accredito',
            description: 'Calendar Match export singleListPdf title label',
          }),
          table: {
            name: intl.formatMessage({
              defaultMessage: 'Nominativo',
              description:
                'Calendar Match export singleListPdf table name label',
            }),
            newspaper: intl.formatMessage({
              defaultMessage: 'Testata',
              description:
                'Calendar Match export singleListPdf table newspaper label',
            }),
            seat: intl.formatMessage({
              defaultMessage: 'N.Posto',
              description:
                'Calendar Match export singleListPdf table seat label',
            }),
          },
        },
      },
      snackbars: {
        zeroCategoryResults: intl.formatMessage({
          defaultMessage:
            'Nessun elemento da esportare per la categoria selezionata',
          description: 'Calendar Match export zero category results',
        }),
        zeroRersults: intl.formatMessage({
          defaultMessage: 'Nessun elemento da esportare',
          description: 'Calendar Match export zero results',
        }),
      },
    },
    creditManagement: {
      title: intl.formatMessage({
        defaultMessage: 'Gestione Accrediti',
        description: 'Calendar credit management page title',
      }),
      buttons: {
        loadFromExcel: intl.formatMessage({
          defaultMessage: 'Carica Fotografi da Excel della Lega',
          description: 'Calendar credit management add credit button',
        }),
        addCredit: intl.formatMessage({
          defaultMessage: 'Aggiungi accredito',
          description: 'Calendar credit management add credit button',
        }),
      },
      filters: {
        resultsCountSuffix: intl.formatMessage({
          defaultMessage: 'Elementi presenti',
          description:
            'Calendar credit management filters results count suffix',
        }),
        options: {
          common: {
            allF: intl.formatMessage({
              defaultMessage: 'Tutte',
              description: 'Calendar credit management filters all F',
            }),
            allM: intl.formatMessage({
              defaultMessage: 'Tutti',
              description: 'Calendar credit management filters all M',
            }),
          },
          status: {
            approved: intl.formatMessage({
              defaultMessage: 'Approvato',
              description: 'Calendar credit management filters status approved',
            }),
            tobeapproved: intl.formatMessage({
              defaultMessage: 'Da approvare',
              description:
                'Calendar credit management filters status tobeApproved',
            }),
            revoked: intl.formatMessage({
              defaultMessage: 'Rifiutato',
              description: 'Calendar credit management filters status revoked',
            }),
          },
        },
        fields: {
          name: intl.formatMessage({
            defaultMessage: 'Nome Cognome',
            description: 'Calendar credit management name field',
          }),
          newspaper: intl.formatMessage({
            defaultMessage: 'Testata giornalistica',
            description: 'Calendar credit management newspaper field',
          }),
          status: intl.formatMessage({
            defaultMessage: 'Stato',
            description: 'Calendar credit management status field',
          }),
          category: intl.formatMessage({
            defaultMessage: 'Categoria',
            description: 'Calendar credit management category field',
          }),
        },
      },
      credits: {
        name: intl.formatMessage({
          defaultMessage: 'Nominativo',
          description: 'Calendar credit management credit name',
        }),
        newspaper: intl.formatMessage({
          defaultMessage: 'Testata',
          description: 'Calendar credit management credit newspaper',
        }),
        category: intl.formatMessage({
          defaultMessage: 'Categoria',
          description: 'Calendar credit management credit category',
        }),
        seat: intl.formatMessage({
          defaultMessage: 'Posto',
          description: 'Calendar credit management credit seat',
        }),
        sideline: intl.formatMessage({
          defaultMessage: 'Interno Campo',
          description: 'Calendar credit management credit sideline',
        }),
        status: {
          Approved: intl.formatMessage({
            defaultMessage: 'Approvato',
            description: 'Calendar credit management credit status approved',
          }),
          Pending: intl.formatMessage({
            defaultMessage: 'Da approvare',
            description:
              'Calendar credit management credit status tobeApproved',
          }),
          Rejected: intl.formatMessage({
            defaultMessage: 'Rifiutato',
            description: 'Calendar credit management credit status revoked',
          }),
          All: intl.formatMessage({
            defaultMessage: 'Tutti',
            description: 'Calendar credit management credit status all',
          }),
        },
      },
      dialog: {
        import: {
          title: intl.formatMessage({
            defaultMessage: 'Caricamento da file Excel della Lega',
            description: 'Calendar credit import dialog title',
          }),
          subtitle: intl.formatMessage({
            defaultMessage: 'Seleziona il file con i record da importare',
            description: 'Calendar credit import dialog subtitle',
          }),
          illustrationTitle: intl.formatMessage({
            defaultMessage: 'Seleziona file',
            description: 'Calendar credit import dialog illustration title',
          }),
          buttons: {
            proceed: intl.formatMessage({
              defaultMessage: 'Procedi',
              description: 'Calendar credit import dialog proceed button',
            }),
          },
        },
        userSelection: {
          title: intl.formatMessage({
            defaultMessage: 'Seleziona un utente per questo accredito',
            description: 'Calendar credit user selction idalog title',
          }),
          subtitle: intl.formatMessage({
            defaultMessage:
              'Seleziona un giornalista / fotografo al quale assegnare il nuovo accredito',
            description: 'Calendar credit user selction idalog subtitle',
          }),
          searchBar: intl.formatMessage({
            defaultMessage: 'Digita il nome di un giornalista o fotografo',
            description: 'Calendar credit user selction idalog subtitle',
          }),
          buttons: {
            proceed: intl.formatMessage({
              defaultMessage: 'Procedi',
              description:
                'Calendar credit user selction idalog proceed button',
            }),
          },
        },
      },
      snackbar: {
        createAccSuccess: intl.formatMessage({
          defaultMessage: 'Accredito creato con successo',
          description: 'Calendar credit management add credit success snackbar',
        }),
      },
    },
    import: {
      snackbars: {
        success: intl.formatMessage({
          defaultMessage: 'Import eseguito con successo',
          description: 'Calendar credit import success',
        }),
        fileFormat: intl.formatMessage({
          defaultMessage:
            "Impossibile eseguire l'import, ricontrolla il formato del file",
          description: 'Calendar credit import invalid file format',
        }),
      },
    },
    importRecap: {
      title: intl.formatMessage({
        defaultMessage: 'Riepilogo import',
        description: 'Calendar credit import recap page title',
      }),
      illustrationTitle: intl.formatMessage({
        defaultMessage: 'Import terminato',
        description: 'Calendar credit import recap illustration title',
      }),
      illustrationSubitle: intl.formatMessage({
        defaultMessage: 'Vedi sotto il riepilogo',
        description: 'Calendar credit import recap illustration subtitle',
      }),
      recapCards: {
        total: intl.formatMessage({
          defaultMessage: 'Record totali',
          description: 'Calendar credit import recap card totals',
        }),
        success: intl.formatMessage({
          defaultMessage: 'Record importati con successo',
          description: 'Calendar credit import recap card success',
        }),
        failed: intl.formatMessage({
          defaultMessage: 'Record non importati',
          description: 'Calendar credit import recap card failed',
        }),
      },
      notImportedtitle: intl.formatMessage({
        defaultMessage: 'Record non importati',
        description: 'Calendar credit import recap not imported section title',
      }),
    },
    creditRequest: {
      title: intl.formatMessage({
        defaultMessage: 'Gestione Accrediti',
        description: 'Calendar credit request page title',
      }),
      currentSeasonRequests: intl.formatMessage({
        defaultMessage: 'Richieste nella stagione corrente',
        description: 'Calendar credit request current season request title',
      }),
      fields: {
        name: intl.formatMessage({
          defaultMessage: 'Nome',
          description: 'Calendar credit request name field',
        }),
        surname: intl.formatMessage({
          defaultMessage: 'Cognome',
          description: 'Calendar credit request surname field',
        }),
        organisation: intl.formatMessage({
          defaultMessage: 'Testata',
          description: 'Calendar credit request organisation field',
        }),
        category: intl.formatMessage({
          defaultMessage: 'Categoria',
          description: 'Calendar credit request category field',
        }),
        birthdate: intl.formatMessage({
          defaultMessage: 'Data di nascita',
          description: 'Calendar credit request birthdate field',
        }),
        birthplace: intl.formatMessage({
          defaultMessage: 'Luogo di nascita',
          description: 'Calendar credit request birthplace field',
        }),
        gender: intl.formatMessage({
          defaultMessage: 'Sesso',
          description: 'Calendar credit request gender field',
        }),
        email: intl.formatMessage({
          defaultMessage: 'Email',
          description: 'Calendar credit request email field',
        }),
        creditRequests: intl.formatMessage({
          defaultMessage: 'Richieste accrediti',
          description: 'Calendar credit request credit requests field',
        }),
        confirmedCreditRequests: intl.formatMessage({
          defaultMessage: 'Richieste accrediti confermati',
          description:
            'Calendar credit request confirmed credit requests field',
        }),
      },
      buttons: {
        assignSeat: intl.formatMessage({
          defaultMessage: 'Assegna posto',
          description: 'Calendar credit request assign seat button',
        }),
        confirm: intl.formatMessage({
          defaultMessage: 'Conferma',
          description: 'Calendar credit request confirm button',
        }),
        rejectRequest: intl.formatMessage({
          defaultMessage: 'Rifiuta richiesta',
          description: 'Calendar credit request reject request button',
        }),
        changeSeat: intl.formatMessage({
          defaultMessage: 'Cambia posto',
          description: 'Calendar credit request change seat button',
        }),
        edit: intl.formatMessage({
          defaultMessage: 'Modifica',
          description: 'Calendar credit request edit button',
        }),
        saveEditing: intl.formatMessage({
          defaultMessage: 'Salva Modifiche',
          description: 'Calendar credit request save edit button',
        }),
      },
      dialog: {
        reject: {
          title: intl.formatMessage({
            defaultMessage:
              'Sei sicuro di voler rifiutare la richiesta di accredito?',
            description: 'Calendar credit request reject dialog title',
          }),
          content: intl.formatMessage({
            defaultMessage:
              "L'utente riceverà una comunicazione al suo indirizzo email",
            description:
              'Calendar credit request reject dialog content message',
          }),
          reject: intl.formatMessage({
            defaultMessage: 'Rifiuta',
            description: 'Calendar credit request reject dialog reject button',
          }),
        },
        confirmPhotographer: {
          title: intl.formatMessage({
            defaultMessage: 'Conferma Posto',
            description: 'Calendar credit request confirm dialog title',
          }),
          message: intl.formatMessage({
            defaultMessage:
              "Sei sicuro di confermare l'accredito per questo fotografo? Gli verrà inviata una notifica via email.",
            description: 'Calendar credit request confirm dialog message',
          }),
          confirmButton: intl.formatMessage({
            defaultMessage: 'Conferma',
            description: 'Calendar credit request confirm dialog assign button',
          }),
        },
      },
      snackbar: {
        rejectAccSuccess: intl.formatMessage({
          defaultMessage: 'Accredito rifiutato con successo',
          description:
            'Calendar credit management credit reject success snackbar',
        }),
        confirmAccSuccess: intl.formatMessage({
          defaultMessage: 'Accredito rifiutato con successo',
          description:
            'Calendar credit management credit reject success snackbar',
        }),
        assignAccSuccess: intl.formatMessage({
          defaultMessage: 'Posto assegnato con successo',
          description:
            'Calendar credit management assign seat success snackbar',
        }),
        creditUpdateSuccess: intl.formatMessage({
          defaultMessage: 'Accredito aggiornato con successo',
          description:
            'Calendar credit management credit update success snackbar',
        }),
      },
    },
    map: {
      title: intl.formatMessage({
        defaultMessage: 'Mappa',
        description: 'Calendar credit map page title',
      }),
      seat: {
        category: intl.formatMessage({
          defaultMessage: 'Categoria',
          description: 'Calendar credit map popover category',
        }),
        organisation: intl.formatMessage({
          defaultMessage: 'Testata',
          description: 'Calendar credit map popover organisation',
        }),
        seat: intl.formatMessage({
          defaultMessage: 'Posto',
          description: 'Calendar credit map popover seat',
        }),
        observerPrefix: intl.formatMessage({
          defaultMessage: 'OB',
          description: 'Calendar credit map observer seat prefix',
        }),
      },
      legend: {
        free: intl.formatMessage({
          defaultMessage: 'Postazione libera',
          description: 'Calendar credit map legend free',
        }),
        assigned: intl.formatMessage({
          defaultMessage: 'Accredito per la partita',
          description: 'Calendar credit map legend assigned',
        }),
        observer: intl.formatMessage({
          defaultMessage: 'Observer',
          description: 'Calendar credit map legend observer',
        }),
      },
      dialog: {
        assign: {
          title: intl.formatMessage({
            defaultMessage: 'Assegna Posto',
            description: 'Calendar credit map assign dialog title',
          }),
          message: intl.formatMessage({
            defaultMessage:
              'Sei sicuro di assegnare il posto al giornalista? Gli verrà inviata una notifica via email.',
            description: 'Calendar credit map assign dialog message',
          }),
          assignButton: intl.formatMessage({
            defaultMessage: 'Assegna',
            description: 'Calendar credit map assign dialog assign button',
          }),
        },
      },
      buttons: {
        export: intl.formatMessage({
          defaultMessage: 'Esporta mappa',
          description: 'Calendar credit map export button',
        }),
      },
    },
    recapSecretariat: {
      title: intl.formatMessage({
        defaultMessage: 'Gestisci Accrediti',
        description: 'Calendar recap secretariat fo title',
      }),
      breadcrumbs: intl.formatMessage({
        defaultMessage: 'Gestione Accrediti',
        description: 'Calendar recap secretariat fo breadcrumbs',
      }),
      cards: {
        sent: intl.formatMessage({
          defaultMessage: 'Richieste di accredito inviate',
          description: 'Calendar recap secretariat fo cards sent',
        }),
        approved: intl.formatMessage({
          defaultMessage: 'Richieste di accredito approvate',
          description: 'Calendar recap secretariat fo cards approved',
        }),
        rejected: intl.formatMessage({
          defaultMessage: 'Richieste di accredito rifiutate',
          description: 'Calendar recap secretariat fo cards rejected',
        }),
      },
      listTitle: intl.formatMessage({
        defaultMessage: 'Richieste di accredito',
        description: 'Calendar recap secretariat fo listTitle',
      }),
      listSubTitle: intl.formatMessage({
        defaultMessage: 'Le richieste dovranno essere approvate da AC Milan',
        description: 'Calendar recap secretariat fo listSubTitle',
      }),
      noUser: intl.formatMessage(
        {
          defaultMessage:
            'Non hai ancora dei giornalisti associati perciò al momento non puoi richiedere accrediti: aspetta qualche giorno che AC Milan aggiunga i tuoi giornalisti, se il problema persiste scrivi a <mailTo>press@acmilan.com</mailTo> la lista dei giornalisti che vorresti abilitare (indicando per ognuno Nome, Cognome, Email, Data e Luogo di Nascita e numero di tessera del giornalista)',
          description: 'Calendar recap secretariat fo no users',
        },
        { ...htmlParserOptions }
      ),
      buttons: {
        cta: intl.formatMessage({
          defaultMessage: 'Richiedi accrediti',
          description: 'Calendar recap secretariat fo buttons cta',
        }),
        approved: intl.formatMessage({
          defaultMessage: 'Richiesta approvata',
          description: 'Calendar recap secretariat fo buttons approved',
        }),
        sent: intl.formatMessage({
          defaultMessage: 'Richiesta inviata',
          description: 'Calendar recap secretariat fo buttons sent',
        }),
        rejected: intl.formatMessage({
          defaultMessage: 'Richiesta rifiutata',
          description: 'Calendar recap secretariat fo buttons rejected',
        }),
      },
    },
  },
});
