import { IntlShape } from 'react-intl';

export const exportTranslations = (intl: IntlShape) => ({
  export: {
    accreditations: {
      surname: intl.formatMessage({
        defaultMessage: 'Cognome',
        description: 'export accreditations surname',
      }),
      name: intl.formatMessage({
        defaultMessage: 'Nome',
        description: 'export accreditations name',
      }),
      birthDate: intl.formatMessage({
        defaultMessage: 'Data Nascita',
        description: 'export accreditations birthDate',
      }),
      city: intl.formatMessage({
        defaultMessage: 'Luogo Nascita',
        description: 'export accreditations city name',
      }),
      gender: intl.formatMessage({
        defaultMessage: 'Sesso',
        description: 'export accreditations gender',
      }),
      category: intl.formatMessage({
        defaultMessage: 'Categoria',
        description: 'export accreditations category',
      }),
      organisation: intl.formatMessage({
        defaultMessage: 'Testata',
        description: 'export accreditations organisation',
      }),
      email: intl.formatMessage({
        defaultMessage: 'Email',
        description: 'export accreditations email',
      }),
    },
    photographers: {
      surname: intl.formatMessage({
        defaultMessage: 'Cognome',
        description: 'export photographers surname',
      }),
      name: intl.formatMessage({
        defaultMessage: 'Nome',
        description: 'export photographers name',
      }),
      organisation: intl.formatMessage({
        defaultMessage: 'Testata',
        description: 'export photographers organisation',
      }),
      category: intl.formatMessage({
        defaultMessage: 'Categoria',
        description: 'export photographers category',
      }),
      birthDate: intl.formatMessage({
        defaultMessage: 'Data Nascita',
        description: 'export photographers birthDate',
      }),
      council: intl.formatMessage({
        defaultMessage: 'Luogo Nascita',
        description: 'export photographers city name',
      }),
      nation: intl.formatMessage({
        defaultMessage: 'Nazione Nascita',
        description: 'export photographers nation name',
      }),
      gender: intl.formatMessage({
        defaultMessage: 'Sesso',
        description: 'export photographers gender',
      }),
      email: intl.formatMessage({
        defaultMessage: 'Email',
        description: 'export photographers email',
      }),
    },
  },
});
