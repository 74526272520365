import { Outlet } from 'react-router-dom';

import {
  ContentWrapper,
  LeftCol,
  RightCol,
  Wrapper,
} from './DoubleColLayout.styles';

import milanBg from 'assets/images/milanBackground.png';
import { Image, Logo, PageFooter, Scrollbar } from 'components';
import { useResponsive } from 'hooks';

const DoubleColLayout = () => {
  const mdUp = useResponsive('up', 'md');
  const renderFooter = () =>
    process.env.REACT_APP_FO_WEB_URL &&
    window.location.href.includes(process.env.REACT_APP_FO_WEB_URL) ? (
      <PageFooter />
    ) : null;
  return (
    <>
      <Wrapper>
        <LeftCol>
          <Image
            src={milanBg}
            sx={{
              height: '100%',
            }}
          />
        </LeftCol>
        <Scrollbar sx={{ width: '100%' }}>
          <RightCol>
            <ContentWrapper>
              <Logo
                sx={{
                  width: mdUp ? 250 : 170,
                  height: 'auto',
                  marginBottom: '50px',
                }}
              />
              <Outlet />
            </ContentWrapper>
          </RightCol>
        </Scrollbar>
      </Wrapper>
      {renderFooter()}
    </>
  );
};

export { DoubleColLayout };
