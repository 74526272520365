import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  mailTo: (str: ReactNode) => (
    <a href={`mailTo: ${str}`} style={{ color: '#D50000' }}>
      {str}
    </a>
  ),
  br: () => <br />,
};

export const contactTranslations = (intl: IntlShape) => ({
  contacts: {
    title: intl.formatMessage({
      defaultMessage: 'Contatti',
      description: 'Contacts Page title',
    }),
    content: intl.formatMessage(
      {
        defaultMessage: `Per informazioni o particolari richieste per quanto riguarda gli accrediti delle partite scrivi a <mailTo>press@acmilan.com</mailTo>.<br></br>
      Per problemi tecnici con la piattaforma scrivi a <mailTo>press_accreditation@acmilan.com</mailTo>. `,
        description: 'Contacts Page content',
      },
      { ...htmlParserOptions }
    ),
  },
});
