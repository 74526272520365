import 'simplebar/src/simplebar.css';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import itDateLocale from 'date-fns/locale/it';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { IntlProvider, HttpProvider } from 'providers';

ReactDOM.render(
  <HelmetProvider>
    <HttpProvider>
      <IntlProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={itDateLocale}
        >
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </IntlProvider>
    </HttpProvider>
  </HelmetProvider>,
  document.getElementById('root')
);
