import { Button, Stack } from '@mui/material';
import { IPageActionsProps } from './PageActions.types';

const PageActions = ({
  cancelText,
  onCancel,
  saveText,
  onSave,
  disableSave,
  disableCancel,
  customWidth,
  otherButtons,
}: IPageActionsProps) => {
  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'flex-end'}
      sx={{ mt: '80px' }}
    >
      {cancelText && onCancel && (
        <Button
          type="reset"
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            marginRight: '10px',
            p: '11px 22px',
            ...(customWidth && { width: `${customWidth}px` }),
          }}
          onClick={() => onCancel()}
          disabled={disableCancel}
        >
          {cancelText}
        </Button>
      )}
      {saveText && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={() => onSave?.()}
          sx={{
            p: '11px 22px',
            ...(customWidth && { width: `${customWidth}px` }),
          }}
          disabled={disableSave}
        >
          {saveText}
        </Button>
      )}
      {otherButtons}
    </Stack>
  );
};
export { PageActions };
